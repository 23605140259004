import * as React from "react";
import HaveYouSeenSection from "../../../components/HaveYouSeenSection/HaveYouSeenSection";
import Layout from "../../../components/Layout/Layout";
import TeamRolesSection from "../../../components/TeamRolesSection/TeamRolesSection";
import TestimonialSection from "../../../components/TestimonialSection/TestimonialSection";
import SubPageTitle from "../../../components/SubPageTitle/SubPageTitle";
import { restManagement } from "../../../components/SubPageTitle/subPageTitleContent";
import { restaurantManagerRoles } from "../../../components/TeamRolesSection/TeamRolesSectionContent";
import { SEO } from "../../../components/seo";
import { descriptions } from "../../../libs/seoContent";

const RestaurantManager = () => {
  return (
    <Layout>
      {/* This the dummy page for you to put your components in */}
      <SubPageTitle content={restManagement} />
      <TeamRolesSection content={restaurantManagerRoles} />
      <TestimonialSection pageTitle={"Restaurant Manager"} className="reduced-padding-bottom" />
      <HaveYouSeenSection page="restaurantManager" />
    </Layout>
  );
};

export default RestaurantManager;

export const Head = () => (
  <SEO title="Restaurant Manager" description={descriptions.restaurantManager} />
);
